.daterange {
  position: relative;
  box-shadow: 0 1px 1px 0 rgba(85, 95, 110, 0.2);
  border-radius: 4px;
}

.daterange * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.daterange div,
.daterange li,
.daterange span,
.daterange ul {
  margin: 0;
  padding: 0;
  border: 0;
}

.daterange ul {
  list-style: none;
}

.daterange.dr-active {
  z-index: 10;
}

.daterange .dr-input {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border: 1px solid #d4d8e3;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: #fff;
  position: relative;
  z-index: 5;
  overflow: hidden;
  height: 46px;
}

.daterange .dr-input:hover {
  border-color: #2693d5;
}

.daterange .dr-input.dr-active {
  -webkit-box-shadow: 0 0 0 3px rgba(38, 147, 213, 0.3);
  box-shadow: 0 0 0 3px rgba(38, 147, 213, 0.3);
  border-color: #2693d5;
}

.daterange .dr-input .dr-dates {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0 1.5rem 0 0.75rem;
  min-width: -webkit-calc(100% - 35px);
  min-width: calc(100% - 35px);
}

.daterange .dr-input .dr-dates .dr-date {
  font-size: 0.9375rem;
  padding: 0.65625rem 0;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  outline: 0;
}

.daterange .dr-input .dr-dates .dr-date.dr-active,
.daterange .dr-input .dr-dates .dr-date:focus,
.daterange .dr-input .dr-dates .dr-date:hover {
  color: #2693d5;
}

.daterange .dr-input .dr-dates .dr-date:empty:after {
  content: attr(placeholder);
  color: #9ba3a7;
}

.daterange .dr-input .dr-dates .dr-dates-dash {
  color: #9ba3a7;
  padding: 0 10px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  font-weight: 600;
}

.daterange .dr-input .dr-presets {
  width: 2.1875rem;
  border-left: 1px solid #d4d8e3;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.daterange .dr-input .dr-presets.dr-active,
.daterange .dr-input .dr-presets:hover {
  border-color: #2693d5;
  -webkit-box-shadow: inset 0 2px 3px #ebf1f4;
  box-shadow: inset 0 2px 3px #ebf1f4;
}

.daterange .dr-input .dr-presets.dr-active .dr-preset-bar,
.daterange .dr-input .dr-presets:hover .dr-preset-bar {
  background-color: #2693d5;
}

.daterange .dr-input .dr-presets .dr-preset-bar {
  height: 2px;
  background-color: #d4d8e3;
  margin: 1px 0 1px 25%;
}

.daterange .dr-input .dr-presets .dr-preset-bar:nth-child(1) {
  width: 50%;
}

.daterange .dr-input .dr-presets .dr-preset-bar:nth-child(2) {
  width: 40%;
}

.daterange .dr-input .dr-presets .dr-preset-bar:nth-child(3) {
  width: 30%;
}

.daterange .dr-selections {
  position: absolute;
}

.daterange .dr-selections .dr-calendar {
  background-color: #fff;
  font-size: 0.9375rem;
  -webkit-box-shadow: 0 0 5px #d4d8e3;
  box-shadow: 0 0 5px #d4d8e3;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 4;
  padding-top: 5px;
  top: -5px;
  left: 4px;
  -webkit-transition: width 0.2s;
  -o-transition: width 0.2s;
  transition: width 0.2s;
  min-width: 210px;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid rgba(195, 202, 205, 0.5);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 1.5625rem;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i {
  color: #d4d8e3;
  position: relative;
  top: -1px;
  cursor: pointer;
  font-size: 0.75rem;
  height: 100%;
  width: 20px;
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i:hover:after,
.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i:hover:before {
  background-color: #2693d5;
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i.dr-disabled {
  pointer-events: none;
  opacity: 0;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-switcher i:after,
.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i:before {
  content: '';
  position: absolute;
  width: 7px;
  height: 2px;
  background-color: #d4d8e3;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  left: 50%;
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i.dr-left:before {
  top: -webkit-calc(50% - 2px);
  top: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i.dr-left:after {
  top: -webkit-calc(50% + 2px);
  top: calc(50% + 2px);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i.dr-right:before {
  top: -webkit-calc(50% - 2px);
  top: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-range-switcher
  .dr-switcher
  i.dr-right:after {
  top: -webkit-calc(50% + 2px);
  top: calc(50% + 2px);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-month-switcher {
  width: 100%;
  margin-right: 0.375rem;
}

.daterange .dr-selections .dr-calendar .dr-range-switcher .dr-year-switcher {
  min-width: 80px;
}

.daterange .dr-selections .dr-calendar .dr-days-of-week-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ebf1f4;
  font-size: 0.625rem;
  color: #9ba3a7;
  padding: 0.3125rem 0;
  border: 1px solid rgba(195, 202, 205, 0.5);
  border-left: none;
  border-right: none;
}

.daterange .dr-selections .dr-calendar .dr-days-of-week-list .dr-day-of-week {
  width: -webkit-calc(100% / 7);
  width: calc(100% / 7);
  text-align: center;
}

.daterange .dr-selections .dr-calendar .dr-day-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 0.9375rem;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day {
  padding: 0.3125rem;
  text-align: center;
  width: -webkit-calc(100% / 7);
  width: calc(100% / 7);
  cursor: pointer;
  color: #4f565c;
}

.daterange
  .dr-selections
  .dr-calendar
  .dr-day-list
  .dr-day.dr-hover:not(.dr-current) {
  background-color: #ebf1f4 !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day.dr-hover-before {
  border-left: 2px solid #2693d5 !important;
  -webkit-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  padding-left: 0.1875rem !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-day.dr-hover-after {
  border-right: 2px solid #2693d5 !important;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  padding-right: 0.1875rem !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-end,
.daterange .dr-selections .dr-calendar .dr-day-list .dr-selected,
.daterange .dr-selections .dr-calendar .dr-day-list .dr-start {
  background-color: #ebf1f4;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-maybe {
  background-color: #ebf1f4 !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-fade {
  color: #d4d8e3;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-start {
  border-left: 2px solid #2693d5;
  -webkit-border-radius: 2px 0 0 2px;
  border-radius: 2px 0 0 2px;
  padding-left: 0.1875rem;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-end {
  border-right: 2px solid #2693d5;
  -webkit-border-radius: 0 2px 2px 0;
  border-radius: 0 2px 2px 0;
  padding-right: 0.1875rem;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-current {
  color: #2693d5 !important;
  background-color: rgba(38, 147, 213, 0.2) !important;
}

.daterange .dr-selections .dr-calendar .dr-day-list .dr-outside {
  pointer-events: none;
  cursor: default;
  color: rgba(195, 202, 205, 0.5);
}

.daterange .dr-selections .dr-preset-list {
  background-color: #fff;
  color: #2693d5;
  font-size: 0.9375rem;
  -webkit-box-shadow: 0 0 5px #d4d8e3;
  box-shadow: 0 0 5px #d4d8e3;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  z-index: 4;
  padding-top: 5px;
  top: -5px;
  left: 4px;
  width: 100%;
}

.daterange .dr-selections .dr-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 0.75rem 0.625rem;
  border-bottom: 1px solid #ebf1f4;
  cursor: pointer;
  white-space: nowrap;
}

.daterange .dr-selections .dr-list-item:hover {
  background-color: #ebf1f4;
}

.daterange .dr-selections .dr-list-item .dr-item-aside {
  color: #9ba3a7;
  font-size: 0.75rem;
  margin-left: 0.3125rem;
  position: relative;
  top: -1px;
}

.daterange--single .dr-input {
  cursor: text;
}

.daterange--single .dr-input .dr-dates {
  padding: 0;
  min-width: 160px;
  width: 100%;
}

.daterange--single .dr-input .dr-dates .dr-date {
  width: 100%;
  padding: 0.65625rem 0.75rem;
  text-align: left;
  color: #4f565c;
}
